import getCountry from '@/utils/getCountry'

function useGTMScroll(percent) {

    window.dataLayer.push({
        event: "uaEvent",
        event_name: "page_scroll",
        event_parameters: {
            scroll_percent: percent
        }
    });
}

function useGTM(event_name, button, general_title, module) {
        window.dataLayer.push({
            event: "uaEvent",
            event_name: event_name,
            event_parameters: {
                button: button,
                general_title: general_title,
                module: module
            }
        });


}
function useGTMInit(content_group, content_id) {
    const region = getCountry()
    window.dataLayer.push({
        event: "uaEvent",
        event_name: 'page_init',
        event_parameters: {
            content_group: content_group,
            content_id: content_id,
            site_language: region
        }
    });


}
export { useGTMScroll, useGTM ,useGTMInit} 