import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"

import { useGTMInit} from '@/hooks/useGTM.js'


const Index = () =>{
  React.useEffect(()=>{
    useGTMInit('support','XREAL_Air2Pro_Quick_Start_Guide_pdf')
  },[])
  return  (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/XREAL_Air2Pro_Quick_Start_Guide.pdf`}
    />
  </>
)
}

export default Index